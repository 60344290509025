<template>
  <div>
    <bw-loader v-if="loading"/>
    <div v-show="!loading" class="bg-white py-40 mb-25">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label">Select Country</label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <select v-model="country" class="form-control admin-input">
                  <option :value="{}">Select Country</option>
                  <option v-for="(country, _index) in globalCountry" :key="_index"
                          :value="{...country}">
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section v-show="!loading && country.name" class="my-40">
      <div class="container">
        <div class="d-lg-flex d-block justify-content-between mt-65 mb-45">
          <div>
            <h2 class="text-primary">
              Resources of {{ country.name }}
            </h2>
            <p> More than one resource can be added by clicking on this button </p>
          </div>
          <div>
            <button class="btn btn-add" @click="addMoreFurtherResource"> +Add</button>
          </div>
        </div>
        <div class="bg-white py-40">
          <div class="row no-gutters p-20 p-lg-0">
            <div class="col-lg-7 col-12 m-auto">
              <div v-for="(_resource, _index) in resources" :key="_index" class="form-group mb-55">
                <div class="form-group mb-30">
                  <div class="form-row">
                    <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                      <label class="col-form-label">File Title</label>
                    </div>
                    <div class="col-12 col-md-8 col-lg-5 col-sm-12">
                      <input v-model="_resource.title" class="admin-input form-control" placeholder="Type file title"
                             type="text">
                      <div
                        v-if="$v.$error & !$v.resources.$each.$iter[_index].title.required"
                        class="error">
                        Title is required.
                      </div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                      <div class="d-inline-flex float-right">
                        <button
                          v-if="resources.length > 0"
                          class="btn btn-add-success float-right"
                          @click="removeFromChildForm(resources, _index, 'api', resourceContentsState)">
                          <i aria-hidden="true" class="fa fa-times"></i> Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-30">
                  <div class="form-row">
                    <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                      <label class="col-form-label">File type</label>
                    </div>
                    <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                      <input v-model="_resource.subtitle" class="admin-input form-control" placeholder="File type"
                             type="text">
                      <div
                        v-if="$v.$error & !$v.resources.$each.$iter[_index].subtitle.required"
                        class="error">
                        Type is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-30">
                  <div class="form-row">
                    <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                      <label class="col-form-label">Date</label>
                    </div>
                    <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                      <input v-model="_resource.date"
                             class="admin-input form-control"
                             name="name" placeholder="Date"
                             type="date">
                      <div
                        v-if="$v.$error & !$v.resources.$each.$iter[_index].date.required"
                        class="error">
                        Date is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-30">
                  <div class="form-row">
                    <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                      <label class="col-form-label">File upload</label>
                    </div>
                    <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                      <div class="drop drop-inline drop-sm mr-30">
                        <div class="cont">
                          <img alt=""
                               src="@/assets/img/icons/upload1.svg">
                          <div v-if="!_resource.file.tsync_id" class="desc"> Upload File</div>
                          <div v-if="_resource.file.tsync_id" class="desc">{{
                              _resource.file.name  | textTruncate(15)
                            }}
                          </div>
                        </div>
                        <input type="file" @change="onFileChange($event,_index)">
                      </div>
                      <div class="d-inline-flex">
                        <i v-show="_resource.file.tsync_id" aria-hidden="true"
                           class="fa fa-times ml-2 clear-file"
                           @click="clearFile(resources, _index)"> Clear</i>
                      </div>
                      <p class="mt-10">
                        Or <br>
                        <input v-model="_resource.more_link" class="mt-10 admin-input form-control"
                               placeholder="Add URL"
                               type="text">
                      </p>
                      <div
                        v-if="$v.$error & !$v.resources.$each.$iter[_index].more_link.requiredUnless"
                        class="error">
                        Either resource file or more link is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="from-group mb-30">
                  <div class="form-row">
                    <div class="col-12 col-md-2 col-lg-4 col-sm-12">
                      <label class="col-form-label">Resource icon</label>
                    </div>
                    <div class="col-12 col-md-10 col-lg-8 col-sm-12 content-block">
                      <div class="drop drop-inline mr-30 drop-sm">
                        <div class="cont">
                          <img
                            v-if="_resource.icon && _resource.icon.previewUrl"
                            :src="_resource.icon && _resource.icon.previewUrl" alt="Icon" class="icon-preview"/>
                          <img
                            v-else-if="_resource.icon && photoPreviewMapping[_resource.icon.tsync_id]"
                            :src="photoPreviewMapping[_resource.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                          <img
                            v-else-if="_resource.icon && !_resource.icon.previewUrl && !_resource.icon.relative_url"
                            alt="" src="@/assets/img/icons/upload1.svg">
                          <img
                            v-else :src="_resource.icon && _resource.icon.relative_url | makeAbsUrl" alt="Icon"
                            class="icon-preview"/>
                          <div v-if="_resource.icon && !_resource.icon.name" class="desc"> Upload Icon</div>
                          <div v-if="_resource.icon && _resource.icon.name" class="desc">
                            {{ _resource.icon.name | textTruncate(15) }}
                          </div>
                        </div>
                        <input
                          accept="image/*"
                          type="file"
                          @change="onIconChange($event,_index)"
                        >
                        <div
                          v-if="$v.$error & !$v.resources.$each.$iter[_index].icon.tsync_id.required"
                          class="error">
                          Icon is required.
                        </div>
                      </div>
                      <div v-if="_resource.icon && _resource.icon.name" class="d-inline-flex">
                        <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                           @click="clearIcon(resources,_index)"> Clear</i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-30">
                  <div class="form-row">
                    <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                      <label class="col-form-label">Order</label>
                    </div>
                    <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                      <input v-model="_resource.order" class="admin-input form-control" placeholder="Order"
                             type="number">
                      <div
                        v-if="$v.$error & !$v.resources.$each.$iter[_index].order.required"
                        class="error">
                        Order is required.
                      </div>
                      <div
                        v-if="$v.$error & !$v.resources.$each.$iter[_index].order.numeric"
                        class="error">
                        Only positive number is acceptable.
                      </div>
                    </div>
                  </div>
                </div>
                <p class="py-10"></p>
              </div>
              <div class="form-group mb-30">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right mt-30">
                      <div class="d-inline-flex">
                        <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit"
                                type="button"
                                @click="saveResources">
                          {{ saveButtonTxt }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="bg-white py-40 mb-25">
      <div class="container">
        <div class="row table-wrapper">
          <div class="content-heading d-lg-flex d-md-block justify-content-between align-items-center">
            <div class="table-responsive">
              <table class="table table-striped text-center">
                <thead>
                <tr>
                  <th v-for="(item, _i) in tableHeads" :key="_i">
                    {{ item.title }} <br/> {{ item.subtitle }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, _rowIndex) in resourceContents" :key="_rowIndex">
                  <td>{{ item.country ? item.country.name : 'N/A' }}</td>
                  <td>{{ item.subtitle }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.date | humanDate }}</td>
                  <td>
                    <a href="">
                      <span v-if="item.icon">
                      <img v-if="!item.icon.relative_url" :src="require(`@/assets/img/icons/story.png`)" alt="Icon"
                           class="resource-icon"
                           @click.prevent="getDownloadLink(item)">
                      <img v-if="item.icon.relative_url" :src="item.icon.relative_url | makeAbsUrl" alt="Icon"
                           class="resource-icon"
                           @click.prevent="getDownloadLink(item)">
                      </span>
                      <span v-else>-</span>
                    </a>
                  </td>
                </tr>
                <tr v-show="resourceContents.length === 0">
                  <td :colspan="tableHeads.length">No data available.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import ResourceMixin from '@/mixins/common/resource'
import { numeric, required, requiredUnless } from 'vuelidate/lib/validators'
import BwLoader from '@/components/ui/BwLoader'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'
import _ from 'lodash'

export default {
  name: 'Resources',
  components: { BwLoader },
  mixins: [FormMixin, validationMixin, UtilityMixin, ResourceMixin],
  data () {
    return {
      country: {},
      loading: false,
      tableHeads: [
        {
          title: 'Country',
          subtitle: ''
        },
        {
          title: 'Type',
          subtitle: ''
        },
        {
          title: 'Name',
          subtitle: ''
        },
        {
          title: 'Date',
          subtitle: ''
        },
        {
          title: 'Download',
          subtitle: ''
        }
      ],
      resources: [
        {
          order: 0,
          title: '',
          subtitle: '', // form field is `type`
          more_link: '', // form field `file upload add url`
          date: null,
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          file: {
            file: null,
            name: null,
            tsync_id: null
          }
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {},
      fileTsyncIdMapping: {}
    }
  },
  computed: {
    resourceContentsState () {
      return this.$store.state.Resources.resourceContents
    },
    globalCountry () {
      return _.sortBy(this.$store.getters.getGlobalCountry, 'name')
    },
    resourceContents () {
      return this.$store.getters.getResourceCountryResources(this.country.name)
    }
  },
  validations: {
    resources: {
      required,
      $each: {
        order: {
          required,
          numeric
        },
        title: {
          required
        },
        subtitle: {
          required
        },
        date: {
          required
        },
        more_link: {
          requiredUnless: requiredUnless(function (item) {
            return item.file.tsync_id !== null
          })
        },
        icon: {
          tsync_id: {
            required
          }
        },
        file: {
          requiredUnless: requiredUnless(function (item) {
            return item.more_link !== null
          })
        }
      }
    }
  },
  methods: {
    addMoreFurtherResource () {
      this.resources.push({
        order: 1,
        title: '',
        subtitle: '', // form field is `type`
        more_link: '', // form field `file upload add url`
        date: '',
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        file: {
          file: null,
          name: null,
          tsync_id: null
        }
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      // if (!this.resources[index].icon) {
      //   this.resources[index].icon = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.resources[index].icon.previewUrl = URL.createObjectURL(file)
        this.resources[index].icon.file = file
        this.resources[index].icon.name = file.name
        this.resources[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.resources[index].icon.previewUrl = null
        this.resources[index].icon.file = null
        this.resources[index].icon.name = null
        this.resources[index].icon.tsync_id = null
      }
    },
    onFileChange (e, index) {
      // if (!this.resources[index].file) {
      //   this.resources[index].file = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.resources[index].file.file = file
        this.resources[index].file.name = file.name
        this.resources[index].file.tsync_id = this.uuidV4()
      } else {
        this.resources[index].file.file = null
        this.resources[index].file.name = null
        this.resources[index].file.tsync_id = null
      }
    },
    async saveResources () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.fileTsyncIdMapping = {}
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}

          const keyStatsPromises = []
          const promises = await vm.resources.reduce(async (memo, v) => {
            await memo
            keyStatsPromises.push(await vm.submitEachFurtherResource(v))
          }, [])
          _.unset(promises, '')
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(keyStatsPromises).then((values) => {
            const filePromises = []
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(iconId, photoFile)
                photoPromises.push(response)
              }

              // file upload
              const fileId = item.data.file ? item.data.file.id : 0
              const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
              if (fileId === 0 || fileTsyncId === '') return
              const file = this.fileTsyncIdMapping[fileTsyncId]
              if (_.get(file, 'file.name')) {
                const fileResponse = this.uploadFile(fileId, file.file)
                filePromises.push(fileResponse)
              }
            })
            // Promise.all(photoPromises).then((iconResponses) => {
            //   this.$notify({
            //     title: 'Resources',
            //     text: 'Resource(s) updated successfully.',
            //     type: 'success', // 'warn', 'error', 'success'
            //     duration: 5000
            //   })
            //   // this.$forceUpdate()
            // })
            Promise.all(filePromises).then((iconResponses) => {
              this.$notify({
                title: `Resource ${this.country.name}`,
                text: 'Resource(s) updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
            })
          })
          await this.$store.dispatch('getResourceContentsFromServer')
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachFurtherResource (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
      if (_.get(data, 'icon.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      }
      if (data.icon && data.icon.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.icon.id && formData.icon.name) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }

      // File handling
      let fileSize = 0
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : vm.uuidV4()
      if (_.get(data, 'file.file')) {
        vm.fileTsyncIdMapping[fileTsyncId] = data.file
      }
      fileSize = _.get(data, 'file.file') ? _.get(data, 'file.file.size') : 0// uploaded file size in KB
      if (!formData.file.id && formData.file.name) {
        formData.file = {
          tsync_id: fileTsyncId,
          extension: formData.file.name.split('.').pop()
        }
      }
      if (!_.get(formData, 'file.tsync_id')) {
        delete formData.file
        formData.file = null
      }
      formData.date = Date.parse(formData.date)
      formData.description = fileSize
      formData.country = this.country.id
      formData.content = this.$store.getters.getContentByAppModule(
        Menus.resources.key).id
      formData.content_type = ContentTypes.RESOURCE_MINI_CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.RESOURCE, Menus.resources.key).id
      return await this.$store.dispatch('createOrUpdateResourceContentItem', formData)
    }
  },
  async created () {
    this.loading = true
    await this.$store.dispatch('getResourceContentsFromServer')
    this.loading = false
  },
  watch: {
    resourceContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          const data = []
          _.cloneDeep(newValue).map((item) => {
            if (!_.get(item, 'file.name')) {
              item.file = {
                file: null,
                name: null,
                tsync_id: null
              }
            }
            if (!_.get(item, 'icon.name')) {
              item.icon = {
                previewUrl: null,
                file: null,
                name: null,
                tsync_id: null
              }
            }
            const _date = item.date
            data.push({
              ...item,
              date: this.$moment(_date).format('yyyy-MM-DD')
            })
          })
          this.resources = data
        } else {
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clear-file {
  cursor: pointer;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
