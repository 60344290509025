<template>
  <Resources></Resources>
</template>

<script>
import Resources from '@/components/admin/resources/Resources'

export default {
  name: 'ResourcesView',
  components: {
    Resources
  }
}
</script>

<style scoped>

</style>
