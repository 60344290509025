import ServerConfig from '@/config/server'

export default {
  name: 'ResourceMixin',
  computed: {},
  filters: {},
  methods: {
    getDownloadLink (resource) {
      if (resource.file) {
        const serverRelativePath = resource.file.relative_url
        const serverUrl = ServerConfig.serverUrl
        if (serverUrl === 'https://admin.trace.nkgbloom.coffee' || serverUrl === 'https://admindemo.portal.nkgbloom.coffee') {
          window.open(`${serverRelativePath}`)
        } else {
          window.open(`${serverUrl}${serverRelativePath}`)
        }
        // _win.focus()
      } else {
        window.open(resource.more_link)
        // _win.focus()
      }
    }
  }
}
